body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background: rgb(204,204,204);
  background: linear-gradient(90deg, rgba(0,0,0,.05) 0%, rgba(255,255,255,1) 100%);
}
.table {
  background: #FFF;
}
/* Mobile responsive breakpoint */
@media (max-width: 990px) {
  body {
    background: #FFF;
  }
  .leaflet-container {
    height: 320px !important;
  }
  .sortable .tagsLabel, .order:nth-last-child(2) {
    display: none;
}
  .filter-label {
    width: 100%;
  }
  [id^="text-filter-column"] {
    margin-left: 0em !important;
  }
  #basic-navbar-nav {
    margin-bottom: 0.75em;
  }
  .navbar .btn.btn-sm {
    margin-bottom: 1em;
  }
  #lastUpdate {
    display: none;
  }
}

/* Missing from Boostrap CSS */
.caret {
  display:inline-block;
  width:0;
  height:0;
  margin-left:2px;
  vertical-align:middle;
  border-top:4px dashed;
  border-top:4px solid\9;
  border-right:4px solid transparent;
  border-left:4px solid transparent
 }
 .dropdown,
 .dropup {
  position:relative
 }
 .dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
 content:"";
 border-top:0;
 border-bottom:4px dashed;
 border-bottom:4px solid\9
}
/* Caret opacity custom defaults */
.react-bootstrap-table-sort-order .caret {
  opacity: 1;
}
.order .dropdown, .order .dropup {
  opacity: 0.4;
}
/* Form styling */
.form-group .btn {
  margin-right: 0.5em;
}
.form-label {
  font-weight: 600;
}
.btn-primary.disabled, .btn-primary:disabled {
  opacity: 0.5;
}
.form-control.dropdown-toggle.btn.btn-secondary {
  width: max-content;
  margin-left: 0.5em;
}
.form-control.dropdown-toggle.btn.btn-secondary {
  background-color: #DDD;
  color: #000;
  border: 0px;
  font-size: 0.95em;  
}
/* Single radio button styling for delete functionality */
.selection-cell input[type="radio"] {
  width: 100%;
}
/* Badge pill variant spacing */
.badge.badge-pill.badge-primary {
  margin-right: 0.25em;
}
/* Table column headers */
.sortable {
  vertical-align: middle !important;
}
.filter-label {
  margin-bottom: 0px !important;
}
[id^="text-filter-column"] {
  background-color: #EEE;
  margin-left: 0.5em;
}
/* Header and nav styling */
.navbar {
  margin-bottom: 0em !important;
}
.navbar-nav a {
  margin-right: 0.25em;
}
.navbar {
  margin-bottom: 1em;
}
#lastUpdate {
  color: #6c757d;
  text-decoration: none;
  cursor: initial;
}
/* mainContainer styling */
.mainContainer {
  margin: 1em;
}
/* recaptcha styling */
#ReCAPTCHA {
  margin: 1em 0em 1em 0em;
}
/* Truncate long links in table using pure CSS */
td a {
  width: 300px;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}
/* Leaflet styling */
.leaflet-container a {
  color: #007bff !important;
}
.leaflet-tooltip span {
  display: block;
}
.leaflet-pane.leaflet-tooltip-pane span:first-of-type {
  font-weight: bold;
}
.leaflet-container .leaflet-control-attribution {
  opacity: 0.75;
}
.leaflet-interactive {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* react-leaflet-markercluster overrides for CircleMarker */
.marker-cluster-small {
  background-color: rgba(51, 136, 255, 1) !important;
}
.marker-cluster-small div {
  background-color: rgb(218, 233, 255) !important;
}
.marker-cluster-medium {
  background-color: rgba(51, 136, 255, 1) !important;
}
.marker-cluster-medium div {
  background-color: rgb(218, 233, 255) !important;
}
.marker-cluster-large {
  background-color: rgba(51, 136, 255, 1) !important;}
.marker-cluster-large div {
  background-color: rgb(218, 233, 255) !important;
}
.marker-cluster-small:hover, .marker-cluster-medium:hover, .marker-cluster-large:hover {
  box-shadow: inset 0 0 0 25px #0056b3 !important;
}
.navbar .btn.btn-success.btn-sm {
  margin-right: 1em;
}
/* scrollTop */
.scrollTop {
  position: fixed;
  display: block;
  bottom: 1em;
  right: 1em;
  width: 45px;
  height: 45px;
  text-decoration: none;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  border-radius: 45px;
}
.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}
/* Twemoji CSS */
.emoji {
  display: inline-block;
  width: auto;
  height: 1em;
  vertical-align: -0.125em;
}